// Legacy Styles - Remove once Angular material is deprecated

// This file contains styles that are making accomodations for upgrading to Angular Material 17, without refactoring all of SD.
// These styles will be deprecated once we transition to Chameleon UI.

mat-datepicker-toggle button {
  width: unset !important;

  span.mat-button-focus-overlay,
  span.mat-button-focus-overlay {
    display: none !important;
  }
}

msp-button {
  .d-flex {
    align-items: center;
  }
}

// Style to fix the additional right border on material notched outline. Can be removed after we remove all Angular Material form controls.
// https://github.com/tailwindlabs/tailwindcss/discussions/9993

.mdc-notched-outline__notch {
  border-right: none !important;
}

:root {
  --mdc-filled-button-label-text-size: 16px;
  --mdc-outlined-button-label-text-size: 16px;
  --mdc-protected-button-label-text-size: 16px;
  --mdc-text-button-label-text-size: 16px;
  --mdc-filled-button-label-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-outlined-button-label-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-protected-button-label-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-outlined-text-field-outline-color: #e0e0e0;
  --mdc-outlined-button-label-text-color: #57586e;
  --mdc-text-button-label-text-color: #57586e;
  --mat-dialog-content-padding: 0px 0px;
  --mdc-plain-tooltip-container-color: #575f65;
  --mdc-plain-tooltip-supporting-text-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --mdc-plain-tooltip-supporting-text-line-height: 1.125rem;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-tracking: 0.026875rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-sidenav-content-text-color: #212121 !important;
  .mat-mdc-button {
    --mat-text-button-horizontal-padding: 16px;
  }
  .mat-primary.mat-mdc-button[disabled],
  mat-primary.mat-mdc-button.mat-mdc-button-disabled {
    --mdc-text-button-disabled-label-text-color: #5d78ff50;
  }
  .mdc-form-field {
    --mdc-form-field-label-text-size: 0.875rem;
  }
  .mat-mdc-slide-toggle {
    --mdc-form-field-label-text-color: var(--mxc-color-Semantic-fg-neutral-subtle-default);
    --mdc-form-field-label-text-size: 0.875rem;
  }
  .mat-mdc-tab-link {
    --mat-tab-header-label-text-tracking: 0.026875rem;
  }
  .mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    --mat-dialog-with-actions-content-padding: 0 24px var(--mxc-space-5) 24px;
  }
  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    --mat-dialog-content-padding: 0 24px var(--mxc-space-5) 24px;
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
  }
  .mat-mdc-dialog-actions {
    --mat-dialog-actions-padding: 0px;
  }
  .mat-mdc-dialog-container .mdc-dialog__title {
    --mdc-dialog-subhead-color: #316771;
    --mdc-dialog-subhead-font: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --mdc-dialog-subhead-line-height: 32px;
    --mdc-dialog-subhead-size: 20px;
    --mdc-dialog-subhead-weight: 500;
    --mdc-dialog-subhead-tracking: inherit;
    --mat-dialog-headline-padding: 0px;
  }
  .mat-mdc-dialog-container .mdc-dialog__content {
    --mdc-dialog-supporting-text-font: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  .mat-mdc-progress-bar.mat-warn {
    --mdc-linear-progress-active-indicator-color: #edbcbc;
  }
}

.type-primary {
  .mdc-button__label {
    color: var(--mxc-color-Semantic-bg-brand-emphasis-default);
  }
}

.mdc-card {
  padding: 16px;
}

.mat-drawer-content {
  background-color: #f6f6fa;
}

.mat-drawer-container {
  color: inherit !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mat-mdc-form-field {
  width: 100%;
  margin: 0 0 1rem 0;
}

.mat-mdc-form-field-subscript-wrapper {
  margin-top: 0.4rem;
}

.mat-mdc-form-field-error {
  font-size: 0.875rem;
}

.mat-grouped-form {
  display: flex;
  align-items: flex-start;
  span {
    display: block;
  }
  msp-button {
    .mdc-button {
      border-radius: 0 5px 5px 0;
      height: 56px;
      padding: 0 2.5rem 0 0.625rem;
    }
  }
  .mat-mdc-form-field {
    display: inline-block;
    width: auto;
    .mdc-notched-outline {
      .mdc-notched-outline__leading {
        border-radius: 0;
      }
      .mdc-notched-outline__trailing {
        border-radius: 0;
      }
    }
    &:first-of-type {
      .mdc-notched-outline {
        .mdc-notched-outline__leading {
          border-radius: 5px 0 0 5px;
        }
      }
    }
  }
}

msp-button {
  display: inline-block;

  msp-icon {
    display: flex;
  }

  & + msp-button {
    margin-left: 1.25rem;
  }

  &.button-full {
    button {
      width: 100%;
    }
  }

  &.button-icon {
    button {
      min-width: 0;
      padding: 0;
      height: 2.25rem;
      width: 2.25rem;
    }
  }

  &.button-icon-round {
    button {
      border-radius: 50%;
      min-width: 0;
      padding: 0;
      height: 2.25rem;
      width: 2.25rem;
    }
  }

  .mdc-button {
    .mdc-button__label {
      display: flex;
      align-items: center;
      letter-spacing: normal;
      position: static;

      mat-progress-bar {
        position: absolute;
        top: 0;
        left: 0;
      }

      .d-flex {
        align-items: center;
      }

      msp-icon + span {
        margin-left: 0.625rem;
      }
    }
  }
  .mat-mdc-unelevated-button[disabled],
  .mat-mdc-unelevated-button.mat-mdc-button-disabled {
    background-color: var(--mxc-color-Semantic-bg-brand-emphasis-default);
    color: #fff;
    opacity: 0.5;
  }

  .mat-mdc-unelevated-button.mat-warn[disabled],
  .mat-mdc-unelevated-button.mat-warn.mat-mdc-button-disabled {
    background-color: #c40000 !important;
    color: #fff;
    opacity: 0.5;
  }
}

.mat-mdc-text-field-wrapper {
  background-color: white;
}

.mat-sidenav-container-primary {
  background-color: #f6f6fa;
}

.modal-dialog {
  position: relative;
  .close-button {
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
.mat-mdc-dialog-container {
  .mdc-dialog__title::before {
    height: 0;
  }

  .mat-mdc-dialog-content {
    overflow: auto;
    display: block;
    margin: 0 -24px;
    max-height: 65vh;
    -webkit-overflow-scrolling: touch;
  }

  .mdc-dialog__surface {
    padding: 24px;
  }
}

.mdc-tooltip {
  border-radius: 4px;
  font-size: 0.8125rem;
  letter-spacing: 0;
  line-height: 1.125rem;
  max-width: 15.625rem !important;
  padding: 0.375rem 0.5rem !important;
  .mdc-tooltip__surface {
    text-align: left;
  }
}

msp-button.input-clear-button button {
  padding: 0;
  font-size: 0.875rem;
  min-width: 36px;
  margin-right: 0.625rem;
}

msp-button.close-button button {
  padding: 0;
  font-size: 0.875rem;
  min-width: 36px;
  margin-right: 0.625rem;
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  flex-grow: 0 !important;
}

.full-width-dialog {
  @media (max-width: 768px) {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh;
    overflow-y: scroll;
  }

  .mat-dialog-container {
    @media (max-width: 768px) {
      border-radius: 0;
      padding: 1.5rem;
    }
  }
}

.bg-primary-alt,
.mat-mdc-card.bg-primary-alt {
  background-color: #f0f2ff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-card.mat-card-accent {
  background-color: #f8b656;
  color: #000;
}

.mat-mdc-card.mat-card-basic {
  background-color: #f6f6fa;
  color: #6a6bb2;
}

.mat-card-group > .mdc-card {
  border-radius: 0;
  box-shadow: none;
}

.mat-card-group > .mdc-card:not(.mat-card-primary):not(.mat-card-accent) {
  border-bottom: solid #c2c2c2 1px;
}

.mat-card-group > .mdc-card:last-child {
  border-bottom: 0 !important;
  border-radius: 0 0 4px 4px;
}

.mdc-card > :last-child:not(.mat-card-footer),
.mat-card-content > :last-child:not(.mat-card-footer) {
  margin-bottom: 0;
}

.mdc-card > :first-child,
.mat-card-content > :first-child {
  margin-top: 0;
}

.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block !important;
}

.mat-mdc-radio-button .mdc-label {
  font-size: 1rem;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
}

// Used in Angular material for spacing above a mat-dialog-title (40px), we don't use it in SD like this so have removed
.mdc-dialog__title::before {
  display: none !important;
}
