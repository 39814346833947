// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @import '@angular/material/theming';
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $msp-support-dashboard-v2-primary: mat-palette($mat-indigo);
// $msp-support-dashboard-v2-accent: mat-palette($mat-pink, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $msp-support-dashboard-v2-warn: mat-palette($mat-red);

// // Create the theme object (a Sass map containing all of the palettes).
// $msp-support-dashboard-v2-theme: mat-light-theme($msp-support-dashboard-v2-primary, $msp-support-dashboard-v2-accent, $msp-support-dashboard-v2-warn);

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($msp-support-dashboard-v2-theme);

// /* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@tailwind base;

@import './background';
@import './pagenotfound';
@import './legacy';

@tailwind components;

.app-loading {
  visibility: hidden;
}

// permission
.permission-outline {
  outline: solid #33ff00 2px;
  position: relative;

  &.permission-outline-empty {
    height: 25px;
  }

  .permission-key {
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 3px 5px;
  }

  .permission-check-passed {
    @extend .permission-key;
    background-color: #33ff0030;
  }

  .permission-check-failed {
    @extend .permission-key;
    background-color: #ff000030;
  }
}

// TODO: put this fix back to msp-fe-libs once ready.
.text-break-with-hyphen {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  hyphens: auto;
}

[mxc-label][required]::after {
  content: ' *';
  vertical-align: middle;
}

.mxc-panel-class .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0;
}

.tab-navigation {
  @apply mxc-flex mxc-gap-1 mxc-list-none;

  li {
    a {
      @apply mxc-text-[#5D6B98] hover:mxc-bg-semantic-bg-neutral-subtle-default hover:mxc-text-semantic-fg-neutral-muted-default;
    }

    &.active {
      a {
        @apply mxc-bg-semantic-bg-brand-subtle-default mxc-text-semantic-fg-brand-default hover:mxc-bg-semantic-bg-brand-subtle-hover hover:mxc-text-semantic-fg-brand-default;
      }
    }
  }
}
